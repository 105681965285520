import { fromEvent as observableFromEvent } from 'rxjs';

import { delay } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { ImagingCenter } from '../model';
import { AppConfigService } from '../app-config.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';

@Component({
	selector: 'ft-login',
	standalone: true,
	templateUrl: './login.component.html',
	imports: [
		ReactiveFormsModule,
		MatFormFieldModule,
		TranslateModule,
		MatInputModule,
		MatIconModule,
		MatSelectModule,
		FormsModule,
		MatButtonModule,
	],
	styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
	title = 'Login';
	form: FormGroup;
	errorMsg: string;
	hide: boolean = true;
	logo: string;
	readonly centers: ImagingCenter[];
	selectedCenter: ImagingCenter;

	constructor(
		private router: Router,
		private authService: AuthService,
		private fb: FormBuilder,
		private _config: AppConfigService
	) {
		this.logo = this._config.logo;
		this.centers = this._config.centers;
		this.selectedCenter = this.centers[0];

		this.form = this.fb.group({
			username: ['', Validators.compose([Validators.required])],
			password: ['', Validators.compose([Validators.required])],
		});
	}

	ngOnInit() {
		observableFromEvent(
			document.getElementById('login'),
			'keyup'
		).subscribe((event: KeyboardEvent) => {
			if (event.code === 'Enter') this.onSubmit();
		});
	}

	onSubmit() {
		// Set the default center, the one which will be sent in header for each request.
		localStorage.setItem('ImagingCenter', this.selectedCenter.id ?? '1');
		this.errorMsg = null;

		this.authService
			.login(this.form.value)
			.pipe(delay(400))
			.subscribe({
				next: data => {
					if (data) {
						this.authService
							.checkUser()
							.subscribe(_ =>
								this.router.navigateByUrl(
									this.authService.redirectUrl
								)
							);
					} else this.errorMsg = 'Login ou mot de passe incorrect.';
				},
			});
	}
}
