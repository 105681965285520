<div
	class="fx-fill-height fx-layout-column fx-content-center fx-items-center"
	id="login">
	<div class="login-card fx-layout-column fx-content-center fx-items-center">
		<div
			class="logo-container fx-layout-row-nowrap fx-content-center fx-bg-transparent">
			<img alt="FireRIS" src="../../assets/images/{{ logo }}" />
		</div>

		<form
			[formGroup]="form"
			class="log-form fx-layout-column fx-content-center fx-items-center">
			<!--username field-->
			<mat-form-field [style.width.px]="320">
				<mat-label>{{ 'USERNAME' | translate }}</mat-label>
				<input
					[placeholder]="'USERNAME' | translate"
					formControlName="username"
					matInput
					[required]="true" />
			</mat-form-field>

			<!--password field-->
			<mat-form-field [style.width.px]="320">
				<mat-label>{{ 'PASSWORD' | translate }}</mat-label>
				<input
					[placeholder]="'PASSWORD' | translate"
					[type]="hide ? 'password' : 'text'"
					formControlName="password"
					matInput
					[required]="true" />
				<mat-icon
					(click)="hide = !hide"
					[fontIcon]="hide ? 'mdi-eye-off' : 'mdi-eye'"
					fontSet="mdi"
					matSuffix></mat-icon>
			</mat-form-field>

			<mat-form-field [style.width.px]="320">
				<mat-label>{{ 'IMAGING_CENTER' | translate }}</mat-label>
				<mat-select
					[(ngModel)]="selectedCenter"
					[ngModelOptions]="{ standalone: true }"
					[placeholder]="'IMAGING_CENTER' | translate">
					@for (center of centers; track center) {
						<mat-option [value]="center">
							{{ center.name }}</mat-option
						>
					}
				</mat-select>
			</mat-form-field>

			@if (errorMsg) {
				@if (errorMsg) {
					<p class="error">{{ errorMsg }}</p>
				}
			}

			<button
				(click)="onSubmit()"
				[disabled]="form.invalid"
				color="primary"
				mat-raised-button
				style="margin-top: 24px"
				type="submit">
				{{ 'LOGIN' | translate }}
			</button>
		</form>
	</div>

	<div class="circle1"></div>
	<div class="circle2"></div>
	<div class="circle3"></div>
	<div class="circle4"></div>

	<img
		[height]="48"
		style="position: fixed; bottom: 24px"
		[src]="'assets/images/firethunder.png'"
		alt="FireThunder SARL" />
</div>
